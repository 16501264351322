<template>
  <v-card flat>
    <v-card-text>
      <v-row>
        <v-col cols="12" sm="6" :md="isAdmin ? 3 : 4">
          <v-checkbox v-model="futureFilter" label="Future chapels only"></v-checkbox>
        </v-col>
        <v-col cols="12" sm="6" :md="isAdmin ? 3 : 4">
          <v-select v-model="termFilter" :items="termFilterOptions" label="Filter by Term"></v-select>
        </v-col>
        <v-col cols="12" :sm="isAdmin ? 8 : 12" :md="isAdmin ? 3 : 4">
          <v-text-field v-model="speakerFilter" label="Filter by Speaker"></v-text-field>
        </v-col>
        <v-col v-if="isAdmin" cols="12" sm="4" md="3">
          <v-switch v-model="showStats" :label="showStats ? 'Stats Shown; Titles Hidden' : 'Stats Hidden; Titles Shown'"></v-switch>
        </v-col>
      </v-row>
    </v-card-text>
    <v-data-table :items="chapels" :headers="headers" :options.sync="options" :server-items-length="totalCount" :footer-props="{ 'items-per-page-options': [5,10,15,20,30,40,50] }" @update:options="load" @dblclick:row="({ target }, { item }) => isAdmin ? $emit('edit', item) : $emit('view', { chapel: item, target: target })">
      <template v-slot:item.date="{ item }">
        {{ item.date | stringFormatDate }}
      </template>
      <template v-slot:item.speaker="{ item }">
        <span v-if="item.speaker">{{ item.speaker }}</span>
        <span v-else style="font-style:italic;font-size:.8em">-- No Speaker Specified --</span>
      </template>
      <template v-slot:item.title="{ item }">
        <span v-if="item.title">{{ item.title }}</span>
        <span v-else style="font-style:italic;font-size:.8em">-- No Title Specified --</span>
      </template>
      <template v-slot:item.actions="{ item }">
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn v-on="on" icon @click="({ target }) => isAdmin ? $emit('edit', item) : $emit('view', { chapel: item, target })">
              <v-icon>fal fa-{{ isAdmin ? 'pencil' : 'eye' }}</v-icon>
            </v-btn>
          </template>
          <span>{{ isAdmin ? 'Edit Chapel' : 'View Chapel Details' }}</span>
        </v-tooltip>
      </template>
    </v-data-table>
  </v-card>
</template>
<script>
import { ref, computed, watch, onBeforeMount, onBeforeUnmount } from '@vue/composition-api'
import { stringFormatDate } from '../../helpers/formatters'

export default {
  setup (props, { root, emit }) {
    const futureFilter = computed({
      get: () => root.$store.state.chapel.scheduleFilter.future,
      set: (future) => {
        root.$store.commit('chapel/setScheduleFilterField', { future })
      }
    })
    const termFilter = computed({
      get: () => root.$store.state.chapel.scheduleFilter.term,
      set: (term) => {
        root.$store.commit('chapel/setScheduleFilterField', { term })
      }
    })
    const termFilterOptions = computed(() => {
      let arr = Object.assign([], root.$store.state.chapel.scheduleTermOptions)
      arr.unshift({ text: 'Any Term', value: '' })
      return arr
    })
    const speakerFilter = computed({
      get: () => root.$store.state.chapel.scheduleFilter.speaker,
      set: (speaker) => {
        root.$store.commit('chapel/setScheduleFilterField', { speaker })
      }
    })
    watch(termFilter, (term) => {
      if (term !== '' && term !== termFilterOptions.value[1].text) {
        futureFilter.value = false
      }
    })

    const isAdmin = computed(() => {
      const roles = root.$store.state.roles
      return 'Technology Services' in roles || 'Chapel Office' in roles
    })

    const chapels = ref([])
    const options = ref({ })
    const totalCount = ref(0)
    const showStats = computed({
      get: () => root.$store.state.chapel.scheduleShowStats,
      set: (val) => root.$store.commit('chapel/setScheduleShowStats', val)
    })

    const headers = computed(() => {
      let arr = [
        { text: 'Date', value: 'date' },
        { text: 'Speaker', value: 'speaker' }
      ]
      if (showStats.value) {
        arr.push({ text: 'Swipe', value: 'credits.swipe' })
        arr.push({ text: 'Sign-In', value: 'credits.signin' })
        arr.push({ text: 'Exemption', value: 'credits.exemption' })
        arr.push({ text: 'Summary', value: 'credits.summary' })
        arr.push({ text: 'Removed', value: 'credits.removed' })
      } else {
        arr.push({ text: 'Title', value: 'title' })
        arr.push({ text: 'Series', value: 'series' })
      }
      if (isAdmin.value) arr.push({ text: 'Actions', value: 'actions' })
      else arr.push({ text: 'View Details', value: 'actions' })
      return arr
    })

    const service = root.$feathers.service('chapel/schedule')

    onBeforeMount(() => {
      service.on('created', load)
      service.on('updated', load)
      service.on('patched', load)
      service.on('removed', load)
    })

    onBeforeUnmount(() => {
      service.removeListener('created', load)
      service.removeListener('updated', load)
      service.removeListener('patched', load)
      service.removeListener('removed', load)
    })

    async function load (newOptions) {
      if (newOptions) options.value = newOptions
      const { sortBy, sortDesc, page, itemsPerPage } = options.value
      let query = {
        $limit: itemsPerPage,
        $skip: (page - 1) * itemsPerPage,
        $sort: {}
      }
      if (Array.isArray(sortBy) && sortBy.length > 0) {
        for (let i = 0; i < sortBy.length; i++) {
          query.$sort[sortBy[i]] = sortDesc[i] ? -1 : 1
        }
      } else {
        query.$sort.date = 1
      }
      if (futureFilter.value) {
        query.date = { $gte: new Date() }
      }
      if (termFilter.value !== '') {
        query.term = termFilter.value
      }
      if (speakerFilter.value !== '') {
        query.speaker = { $regex: speakerFilter.value, $options: 'i' }
      }

      try {
        const { data, total } = await service.find({ query })
        if (data.length === 0 && total > 0) {
          load({ ...options.value, page: 1 })
          return
        }
        totalCount.value = total
        if (showStats.value) {
          const $match = { 'credits.chapel': { $in: data.map(({ _id }) => _id) } }
          if (termFilter.value !== '') $match.term = termFilter.value
          const aggregate = [
            { $match },
            { $unwind: { path: '$credits' } },
            { $match: { 'credits.chapel': { $in: data.map(({ _id }) => _id) } } },
            { $group: { _id: { chapel: '$credits.chapel', type: '$credits.type' }, count: { $sum: 1 } } },
            { $group: { _id: '$_id.chapel', credits: { $push: { type: '$_id.type', count: '$count' } } } }
          ]
          const { data: statData } = await root.$feathers.service('chapel/student').find({ query: { aggregate } })
          chapels.value = []
          data.forEach((row) => {
            const temp = statData.filter(({ _id }) => _id === row._id)
            const credits = {}
            if (temp.length > 0) {
              const creditArr = temp[0].credits
              for (const { type, count } of creditArr) {
                credits[type.replace('-', '').toLowerCase()] = count
              }
            }
            chapels.value.push({ ...row, credits })
          })
        } else {
          chapels.value = data
        }
      } catch (e) {
        root.$store.dispatch('main/snackbar', { type: 'error', text: 'Error loading chapels: ' + e })
      }
    }

    watch([ futureFilter, termFilter, speakerFilter, showStats ], () => {
      load()
    })

    return {
      futureFilter,
      termFilter,
      termFilterOptions,
      speakerFilter,
      isAdmin,
      chapels,
      options,
      totalCount,
      showStats,
      headers,
      load
    }
  },
  filters: {
    stringFormatDate
  }
}
</script>
