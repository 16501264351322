var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{attrs:{"flat":""}},[_c('v-card-text',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"6","md":_vm.isAdmin ? 3 : 4}},[_c('v-checkbox',{attrs:{"label":"Future chapels only"},model:{value:(_vm.futureFilter),callback:function ($$v) {_vm.futureFilter=$$v},expression:"futureFilter"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"6","md":_vm.isAdmin ? 3 : 4}},[_c('v-select',{attrs:{"items":_vm.termFilterOptions,"label":"Filter by Term"},model:{value:(_vm.termFilter),callback:function ($$v) {_vm.termFilter=$$v},expression:"termFilter"}})],1),_c('v-col',{attrs:{"cols":"12","sm":_vm.isAdmin ? 8 : 12,"md":_vm.isAdmin ? 3 : 4}},[_c('v-text-field',{attrs:{"label":"Filter by Speaker"},model:{value:(_vm.speakerFilter),callback:function ($$v) {_vm.speakerFilter=$$v},expression:"speakerFilter"}})],1),(_vm.isAdmin)?_c('v-col',{attrs:{"cols":"12","sm":"4","md":"3"}},[_c('v-switch',{attrs:{"label":_vm.showStats ? 'Stats Shown; Titles Hidden' : 'Stats Hidden; Titles Shown'},model:{value:(_vm.showStats),callback:function ($$v) {_vm.showStats=$$v},expression:"showStats"}})],1):_vm._e()],1)],1),_c('v-data-table',{attrs:{"items":_vm.chapels,"headers":_vm.headers,"options":_vm.options,"server-items-length":_vm.totalCount,"footer-props":{ 'items-per-page-options': [5,10,15,20,30,40,50] }},on:{"update:options":[function($event){_vm.options=$event},_vm.load],"dblclick:row":function (ref, ref$1) {
	var target = ref.target;
	var item = ref$1.item;

	return _vm.isAdmin ? _vm.$emit('edit', item) : _vm.$emit('view', { chapel: item, target: target });
}},scopedSlots:_vm._u([{key:"item.date",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("stringFormatDate")(item.date))+" ")]}},{key:"item.speaker",fn:function(ref){
var item = ref.item;
return [(item.speaker)?_c('span',[_vm._v(_vm._s(item.speaker))]):_c('span',{staticStyle:{"font-style":"italic","font-size":".8em"}},[_vm._v("-- No Speaker Specified --")])]}},{key:"item.title",fn:function(ref){
var item = ref.item;
return [(item.title)?_c('span',[_vm._v(_vm._s(item.title))]):_c('span',{staticStyle:{"font-style":"italic","font-size":".8em"}},[_vm._v("-- No Title Specified --")])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":""},on:{"click":function (ref) {
	var target = ref.target;

	return _vm.isAdmin ? _vm.$emit('edit', item) : _vm.$emit('view', { chapel: item, target: target });
}}},on),[_c('v-icon',[_vm._v("fal fa-"+_vm._s(_vm.isAdmin ? 'pencil' : 'eye'))])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.isAdmin ? 'Edit Chapel' : 'View Chapel Details'))])])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }